import React from "react";
import "./Home.css";
import background from "../images/background.png";

function Home() {
  return (
    <section className="home">
      <img src={background} alt="Background" className="background-image" />
      <div className="overlay"></div>
      <div className="content">
        <div className="first-line"> Happy Birthday, Willynn! 🌟 </div>
        <div>
          Today we celebrate you – a strong, resilient, and inspiring woman who
          brings so much light and positivity to those around her. Your strength
          and determination are truly admirable, and the way you handle life's
          challenges with grace is a reminder of how powerful and amazing you
          are. This year and forever more, don’t let those who don’t treasure
          you take your time and energy – you deserve so much better. Your worth
          is immeasurable, and your time is too precious to be spent on anyone
          who doesn’t recognize your incredible value. I hope this year brings
          you endless joy, love, and success in everything you set your mind to.
          May your dreams soar higher than ever, and may you continue to inspire
          and uplift everyone lucky enough to know you. Wishing you all the
          happiness and blessings in the world on your special day and always.
          You deserve it all!
        </div>
        <div className="last-line">With Love, Cress 💖 </div>
      </div>
    </section>
  );
}

export default Home;
